import fetch from '@/libs/fetch'
import i18n from '@/locale'
export default class Vip {
	/**
	 * @description 会员信息
	 * @param {string} id 会员id
	 */
	static getFsUserInfo(params) {
		return fetch.get(`/fs_users/${params}`)
	}

	/**
	 * @description 会员资料列表
	 * @param {string} username 账号
	 */
	static getFsUserList(params) {
		return fetch.post('/fs_users/search', params)
	}
	
	/**
	 * @description 会员客服资料列表
	 * @param {string} username 账号
	 */
	static getFsUserCustomerList(params) {
		return fetch.post('/fs_users/searchcustomer', params)
	}

	/**
	 * @description 目标会员账号
	 * @param {string} username 账号
	 */
	static getFsUserTargetMembersList(params) {
		return fetch.post('/fs_users/target_members', params)
	}
	/**
	 * @description 会员列表
	 * @param {string} username 账号
	 */
	static getFsUserMyusers(params) {
		return fetch.post('/fs_users/myusers', params)
	}

	/**
	 * @description 获取组内成员
	 * @param {string}
	 */
	static getFsUserManager(params) {
		return fetch.post('/fs_users/get_manager', params)
	}

	/**
	 * @description 变更维护人
	 * @param {string}
	 */
	static updateManagerId(params) {
		return fetch.post('/fs_users/update_manager_id', params)
	}

	/**
	 * @description 代理商列表
	 */
	static getAgentList(params) {
		return fetch.get('/agent', {
			params
		})
	}

	// 意向会员列表

	static getIntendedMember(params) {
		return fetch({
			url: '/fs_users/search_users',
			method: 'post',
			params
		})
	}
	/**
	 * @description 会员导出
	 * @param {object} param 同会员列表查询
	 */
	static exportFsUser(params) {
		return fetch.post('/fs_users/export', params)
	}
	/**
	 * @description 修改删除会员标签,等级,微信
	 * @param {number} id 会员id
	 * @param {string} wx 会员微信
	 * @param {String} level_id 会员等级
	 * @param {string} note 备注内容
	 */
	static updateUserInfo(id, params) {
		return fetch.patch(`/fs_users/${id}`, params)
	}

	/**
	 * @description 管理维护人
	 * @param {number} id 会员id
	 * @param {number} manager_id 维护人id
	 */
	static updateManageInfo(id, params) {
		return fetch.patch(`/fs_users/${id}/manager_id`, params)
	}

	/**
	 * @description 查看会员已添加的社交软件
	 * @param {number} fs_user_id 会员id
	 * @param {number} im_id 社交软件id
	 */
	static checkFsUserImList(id) {
		return fetch.get(`/fs_users/${id}/ims`)
	}
	/**
	 * @description 会员新增社交软件
	 * @param {number} fs_user_id 会员id
	 * @param {number} im_id 社交软件id
	 */
	static addImToFsUser(id, params) {
		return fetch.post(`/fs_users/${id}/ims`, params)
	}
	/**
	 * @description 删除会员社交软件
	 * @param {number} id 会员社交软件记录id
	 */
	static deleteImToFsUser(id) {
		return fetch.delete(`/fs_users/ims/${id}`)
	}
	/**
	 * @description 会员新增通话标记
	 * @param {number} fs_user_id 会员id
	 * @param {string} content 通话标记
	 */
	static addCallStatusToFsUser(id, params) {
		return fetch.post(`/fs_users/${id}/dials`, params)
	}
	/**
	 * @description 会员新增通话标记
	 * @param {number} id 会员id
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static queryCallStatusToFsUser(id, params) {
		return fetch.get(`/fs_users/${id}/dials`, {
			params
		})
	}
	/**
	 * @description 会员新增标签
	 * @param {number} id 会员id
	 * @param {number} tag_id 标签id
	 */
	static addTagsToFsUser(id, params) {
		return fetch.post(`/fs_users/${id}/tags`, params)
	}
	/**
	 * @description 会员删除标签
	 * @param {number} id 会员id
	 * @param {number} tag_id 标签id
	 */
	static deleteTagsToFsUser(id, tagid) {
		return fetch.delete(`/fs_users/${id}/tags/${tagid}`)
	}
	/**
	 * @description 标签列表
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static getFsUserTags(params) {
		return fetch.get('/tags', {
			params
		})
	}
	/**
	 * @description 新增标签
	 * @param {string} name 标签名称
	 * @param {string} color 标签颜色
	 * @param {string} description 标签描述
	 */
	static addFsUserTags(params) {
		return fetch.post('/tags', params)
	}
	/**
	 * @description 修改标签
	 * @param {number} id 标签id
	 * @param {string} name 标签名称
	 * @param {string} color 标签颜色
	 */
	static updateFsUserTags(id, params) {
		return fetch.patch(`/tags/${id}`, params)
	}
	/**
	 * @description 删除标签
	 * @param {number} id 标签id
	 */
	static deleteFsUserTags(id) {
		return fetch.delete(`/tags/${id}`)
	}
	/**
	 * @description 会员等级
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static getFsUserLevels(params) {
		return fetch.get('/levels', {
			params
		})
	}
	/**
	 * @description 新增等级
	 * @param {string} name 等级名称
	 * @param {string} color 等级颜色
	 */
	static addFsUserLevels(params) {
		return fetch.post('/levels', params)
	}
	/**
	 * @description 修改等级
	 * @param {number} id 等级id
	 * @param {string} name 等级名称
	 * @param {string} color 等级颜色
	 */
	static updateFsUserLevels(id, params) {
		return fetch.patch(`/levels/${id}`, params)
	}
	/**
	 * @description 删除等级
	 * @param {number} id 等级id
	 */
	static deleteFsUserLevels(id) {
		return fetch.delete(`/levels/${id}`)
	}
	/**
	 * @description 通用基础数据
	 */
	static async getBaseData() {
		const res = await fetch.get('/commons/search')
		res.data.dial_status.unshift({ id: 9999, name: i18n.t('暂无标记') })
		return res
	}
	/**
	 * @description 会员笔记列表
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static getMemberNoteList(params) {
		return fetch.get('/groups', {
			params
		})
	}
	/**
	 * @description 新增会员笔记
	 * @param {string} name 笔记名称
	 * @param {string} description 笔记描述
	 */
	static addMemberNote(params) {
		return fetch.post('/groups', params)
	}
	/**
	 * @description 修改会员笔记
	 * @param {string} name 笔记名称
	 * @param {string} description 笔记描述
	 */
	static updateMemberNote(id, params) {
		return fetch.patch(`/groups/${id}`, params)
	}
	/**
	 * @description 删除会员笔记
	 * @param {number} id 笔记id
	 */
	static deleteMemberNote(id) {
		return fetch.delete(`/groups/${id}`)
	}
	/**
	 * @description 给笔记中的会员打标签
	 * @param {number} group_id 笔记id
	 * @param {number} tag_id 标签id
	 */
	static addTagToNoteFsUser(params) {
		return fetch.post('/groups/fs_user_tag', params)
	}
	/**
	 * @description 给会员发送短信
	 * @param {string} content 短信内容
	 * @param {number} fs_user_id 会员id
	 */
	static sendSms(params) {
		return fetch.post(`/sms/send`, params)
	}
	/**
	 * @description 批量发送短信
	 * @param {string} content 短信内容
	 * @param {Array} to 会员id
	 */
	static sendSmsMore(params) {
		return fetch.post('/sms/task', params)
	}
	/**
	 * @description 拨打电话所需信息
	 * @param {number} id 会员id
	 */
	// eslint-disable-next-line no-unused-vars
	static callPhone(id, channelRouteId) {
		// if (channelRouteId == 1) {
		// 	return fetch.get(`/fs_users/${id}/making_phone`)
		// } else if (channelRouteId == 2) {
		// 	return fetch.get(`/fs_users/${id}/crm_call`)
		// } else {
		// 	return fetch.get(`/fs_users/${id}/dialto`)
		// }
		return fetch.get(`/fs_users/${id}/dialto_call`)
	}
	/**
	 * @description 拨打电话所需信息
	 * @param {number} id 会员id
	 */
	static changeCallPhone(id) {
		// return fetch.get(`/fs_users/${id}/dialtoSj`)
		return fetch.get(`/fs_users/${id}/dialtoRj`)
	}
	/**
	 * @description 获取会员的交易信息
	 * @param {number} id 会员id
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static getFsUserBusiness(id, params) {
		return fetch.get(`/fs_users/${id}/cash_log`, {
			params
		})
	}
	/**
	 * @description 获取会员的回访记录
	 * @param {number} id 会员id
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static getFsUserDialog(id, params) {
		return fetch.get(`/fs_users/${id}/dial_log`, {
			params
		})
	}
	/**
	 * @description Members upload API
	 * @param {file} file members file
	 * @returns File path
	 */
	static uploadMembersFile(file) {
		return fetch.post('/api/v1/upload', file)
	}
	/**
	 * @description 会员单个加入笔记
	 * @param {number} group_id 笔记id
	 * @param {number} fs_user_id 会员id
	 */
	static addNoteToFsUser(params) {
		return fetch.post('/groups/fs_user_add', params)
	}
	/**
	 * @description 批量会员加入笔记
	 * @param {number} group_id 笔记id
	 * @param {Array} ids 会员ids
	 */
	static addMoreNoteToFsUser(params) {
		return fetch.post('/groups/fs_user_task', params)
	}
	/**
	 * @description 忽略财务层级列表
	 * @param {number} page 页码
	 * @param {number} per_page 条数
	 */
	static getIngoreLevsList(params) {
		return fetch.get('/ignore_levs', { params })
	}
	/**
	 * @description 新增财务层级
	 * @param {string} name 财务层级名称
	 * @param {string} description 描述
	 */
	static addIngoreLevs(params) {
		return fetch.post('/ignore_levs', params)
	}
	/**
	 * @description 修改财务层级
	 * @param {number} id    层级id
	 * @param {string} name 财务层级名称
	 * @param {string} description 描述
	 */
	static updateIngoreLevs(id, params) {
		return fetch.patch(`/ignore_levs/${id}`, params)
	}
	/**
	 * @description 修改财务层级
	 * @param {number} id    层级id
	 */
	static deleteIngoreLevs(id) {
		return fetch.delete(`/ignore_levs/${id}`)
	}

	/**
	 * @description 获取代理列表数据
	 * @param {number} page           页码
	 * @param {number} per_page       条数
	 * @param {string} username       账号
	 * @param {string} true_name      姓名
	 * @param {string} phone_num      手机号
	 * @param {Array}  register_time  注册时间
	 * @param {Array}  last_login_time  最近登录
	 */
	static queryProxyList(data) {
		return fetch.post('/fs_agents/search', data)
	}

	/**
	 * @description 代理列表导出
	 * @param {*} 同代理列表参数
	 */
	static exportProxyList(data) {
		return fetch.post('/fs_agents/export', data)
	}
	/**
	 * @description 代理拨打电话所需信息
	 * @param {number} id 会员id
	 */
	static callPhoneProxy(id) {
		return fetch.get(`/fs_agents/${id}/dialto`)
	}
	/**
	 * @description 号码更换列表
	 * @param {*}
	 */
	static getNumber(params) {
		return fetch.get('review/list', { params })
	}
	/**
	 * @description 查询会员等级列表
	 * @param {number} page 页码
	 * @param {number} per_page 页码
	 */
	static queryAllRank(params) {
		return fetch.get('/ranks', { params })
	}
	/**
	 * @description 添加会员等级
	 * @param {string} name 层级名
	 * @param {string} color 颜色
	 * @param {string} points 所需经验
	 * @param {number} status 状态 0 停用  1 启用
	 */
	static addMemberRank(data) {
		return fetch.post('/ranks', data)
	}
	/**
	 * @description 修改会员等级
	 * @param {string} name 层级名
	 * @param {string} color 颜色
	 * @param {string} points 所需经验
	 * @param {number} status 状态 0 停用  1 启用
	 */
	static updateMemberRank(id, data) {
		return fetch.patch(`/ranks/${id}`, data)
	}
	// static getMemberRank(id) {
	// 	return fetch.get(`/review/${id}`)
	// }
	// 手机审核提交
	static updateReview(id, data) {
		return fetch.patch(`/review/${id}`, data)
	}
	/**
	 * @description 删除会员等级
	 * @param {string} id 层级id
	 */
	static deleteMemberRank(id) {
		return fetch.delete(`/ranks/${id}`)
	}
	/**
	 * @description 经验预设
	 * @param {number} recharge 存款金额
	 * @param {number} recharge_points 存款可得经验
	 * @param {number} code 有效打码
	 * @param {number} code_points 有效打码可得经验
	 */
	static updateExperience(data) {
		return fetch.patch('/configs/rank', data)
	}
	/**
	 * @description 查询重复会员
	 * @param {number} page       // 页码
	 * @param {number} per_page   // 当前页数
	 * @param {string} duplicate_type 重复类型 phone_num true_name
	 * @param {string} duplicate_username 会员账号
	 */
	static queryDuplicateMember(data) {
		return fetch.post('/fs_users/duplicate', data)
	}
	/**
	 * @description 查询重复会员
	 * @param {number} page       // 页码
	 * @param {number} per_page   // 当前页数
	 * @param {string} duplicate_type 重复类型 phone_num true_name
	 * @param {string} duplicate_filter
	 */
	static queryDuplicateDetails(data) {
		return fetch.post('/fs_users/duplicate_users', data)
	}
	/**
	 * @description 获取批量拨打电话的会员
	 * @param {array} ids       // 会员user_id
	 */
	static queryCallMemberDetails(data) {
		return fetch.post('/fs_users/detail', data)
	}
	/**
	 * @description 立即更新
	 * @param {username} username//用户名
	 */
	static updateNow(data) {
		return fetch.get('/crawls/addTask/' + data)
	}
	// 会员上传维护
	static uploadVip(data) {
		return fetch({
			url: '/fs_users/batch_update_manage_id',
			method: 'post',
			data
		})
	}
	// 绑定chat
	static bindChat(data) {
		return fetch({
			url: `/fs_users/${data.id}/chatBind`,
			method: 'post',
			data
		})
	}

	// 解绑chat
	static unbindChat(id) {
		return fetch({
			url: `/fs_users/${id}/chatUnBind`,
			method: 'PATCH'
		})
	}
	// 发送chat消息
	static sendChat(data) {
		return fetch({
			url: `/fs_users/${data.id}/chatSendMsg`,
			method: 'post',
			data
		})
	}
	// 获取验证码
	static getCode(data) {
		return fetch({
			url: `/fs_users/${data.id}/getChatVerificationCode`,
			method: 'post',
			data
		})
	}
	static manualCall(data) {
		return fetch({
			url: `/fs_users/manualDial`,
			method: 'post',
			data
		})
	}
	
	// 添加会员到OA
	static addToOa(data) {
		return fetch({
			url: `/fs_users/addtooa`,
			method: 'post',
			data
		})
	}
	static getNoteRecords(id) {
		return fetch.get(`/fs_users/${id}/note_records`)
	}
	// 获取专员wsid
	static getManagerWsids() {
		return fetch.get(`/managers/getmanagerwsids`)
	}


}
