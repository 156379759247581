<template>
	<div class="ele-menu">
		<Menu :theme="theme3" :active-name="$route.name" style="width: 170px; height: 100%">
			<MenuGroup :title="$t('会员')">
				<MenuItem name="import-members" :to="{ name: 'import-members' }" v-if="importMemberAccess"> <Icon type="md-build" />{{ $t('导入会员') }} </MenuItem>
				<MenuItem name="number-import" :to="{ name: 'number-import' }" v-if="importPhoneAccess"> <Icon type="md-document" />{{ $t('号码导入') }} </MenuItem>
				<MenuItem name="change-number" :to="{ name: 'change-number' }" v-if="reviewListAccess"> <Icon type="md-document" />{{ $t('更换手机申请') }} </MenuItem>
				<MenuItem name="group-call" :to="{ name: 'group-call' }" v-if="callTaskQueryAccess"> <Icon type="md-document" />{{ $t('群呼任务') }} </MenuItem>
				<MenuItem name="member-data" :to="{ name: 'member-data'}" v-if="usersMemberAccess"> <Icon type="md-document" />{{ $t('会员资料') }} </MenuItem>
				<MenuItem name="member-data-customer" :to="{ name: 'member-data-customer',params:{isShowcustomer:true} }"  v-if="customerUsersMemberAccess"> <Icon type="md-document" />{{ $t('客服会员') }} </MenuItem>
				<MenuItem name="member-pullback" :to="{ name: 'member-pullback' }" v-if="pullbackMemberAccess"> <Icon type="md-document" />{{ $t('会员拉回') }} </MenuItem>
				<MenuItem name="member-list" :to="{ name: 'member-list' }" v-if="memberListAccess"> <Icon type="logo-vimeo" />{{ $t('会员列表') }} </MenuItem>
				<MenuItem name="agents-list" :to="{ name: 'agents-list' }" v-if="agentsListAccess"> <Icon type="logo-vimeo" />{{ $t('代理商列表') }} </MenuItem>
				<MenuItem name="agent-detail" :to="{ name: 'agent-detail' }" v-if="agentDetailAccess"> <Icon type="logo-vimeo" />{{ $t('代理资料') }} </MenuItem>
				<MenuItem name="intended-member" :to="{ name: 'intended-member' }" v-if="intendedMemberAccess">
					<Icon type="logo-vimeo" />{{ $t('意向会员') }}
				</MenuItem>
				<!-- <MenuItem name="member-control" :to="{ name: 'member-control' }" v-if="membercontrolAccess"> <Icon type="logo-vimeo" />{{ $t('操作台') }} </MenuItem> -->
				<MenuItem name="member-control_new" :to="{ name: 'member-control_new' }" v-if="membercontrolAccess"> <Icon type="logo-vimeo" />{{ $t('操作台') }} </MenuItem>
				<MenuItem name="view-work-records" :to="{ name: 'view-work-records' }" v-if="viewWorkRecords"> <Icon type="logo-vimeo" />{{ $t('查看工作记录') }} </MenuItem>
				<MenuItem name="member-appeal" :to="{ name: 'member-appeal' }" v-if="memberAppealAccess"> <Icon type="logo-vimeo" />{{ $t('申诉列表') }} </MenuItem>
				<MenuItem name="member-active" :to="{ name: 'member-active' }" v-if="memberActiveAccess"> <Icon type="logo-vimeo" />{{ $t('活动码激活') }} </MenuItem>
				<MenuItem name="member-target" :to="{ name: 'member-target' }" v-if="targetMemberAccess"> <Icon type="md-document" />{{ $t('目标会员') }} </MenuItem>
				<MenuItem name="duplicate-member" :to="{ name: 'duplicate-member' }" v-if="duplicateMemberRight"> <Icon type="md-document" />{{ $t('重复会员') }} </MenuItem>
				<MenuItem name="member-level" :to="{ name: 'member-level' }" v-if="memberLevelRight"> <Icon type="md-reorder" />{{ $t('会员等级') }} </MenuItem>
				<MenuItem name="member-vip-level" :to="{ name: 'member-vip-level' }" v-if="memberVipLevel"> <Icon type="md-reorder" />{{ $t('会员层级') }} </MenuItem>
				<MenuItem name="member-note" :to="{ name: 'member-note' }" v-if="memberNoteRight" > <Icon type="md-chatbubbles" />{{ $t('会员笔记') }} </MenuItem>
				<MenuItem name="proxy-list" :to="{ name: 'proxy-list' }" v-if="proxyRight"> <Icon type="ios-cloudy-night" />{{ $t('代理列表') }} </MenuItem>
				<MenuItem name="roster-manage" :to="{ name: 'roster-manage' }" v-if="packageRight"> <Icon type="ios-list" />{{ $t('名单管理') }} </MenuItem>
				<MenuItem name="login-record" :to="{ name: 'login-record' }" v-if="loginLogRight"> <Icon type="md-exit" />{{ $t('登录日志') }} </MenuItem>
				<MenuItem name="test-call" :to="{ name: 'test-call' }" v-if="testCallRight"> <Icon type="ios-list" />{{ $t('测试拨打') }} </MenuItem>
				<MenuItem name="commissioner-statistics" :to="{ name: 'commissioner-statistics' }" v-if="settlementRight">
					<Icon type="ios-stats-outline" />{{ $t('专员数据统计') }}</MenuItem
				>
				<MenuItem name="achievement-manage" :to="{ name: 'achievement-manage' }" v-if="commissionScheduleRight">
					<Icon type="ios-stats-outline" />{{ $t('业绩管理') }}</MenuItem
				>
				<MenuItem name="sale-data" :to="{ name: 'sale-data' }" v-if="dxDataAccess"> <Icon type="ios-list" />{{ $t('电销数据') }} </MenuItem>
				<MenuItem name="channel-management" v-if="channelAccess" :to="{ name: 'channel-management' }"> <Icon type="ios-list" />{{ $t('渠道管理') }} </MenuItem>
				<MenuItem name="intention-specialist" v-if="kanbanAccess" :to="{ name: 'intention-specialist' }">
					<Icon type="ios-list" />{{ $t('电销个人看板') }}
				</MenuItem>
				<MenuItem v-hasPerm="['vpphoneTask.index']" name="vpphone-list" :to="{ name: 'vpphone-list' }">
					<Icon type="ios-list" />{{ $t('vpphone群呼') }}
				</MenuItem>
				<MenuItem v-hasPerm="['managephonetask.index']" name="managephone-task-list" :to="{ name: 'managephone-task-list' }">
					<Icon type="ios-list" />{{ $t('NewPhone群呼') }}
				</MenuItem>
				<MenuItem v-hasPerm="['platformphonetask.index']" name="platformphone-task-list" :to="{ name: 'platformphone-task-list' }">
					<Icon type="ios-list" />{{ $t('平台群呼') }}
				</MenuItem>
				<!-- <MenuItem v-hasPerm="['vpphonetask.taskPage']" name="search-vpphone-list" :to="{ name: 'search-vpphone-list' }">
					<Icon type="ios-list" />{{ $t('查询重呼任务') }}
				</MenuItem> -->
			</MenuGroup>
			<MenuGroup :title="$t('记录')">
				<MenuItem name="sale-record" :to="{ name: 'sale-record' }"> <Icon type="ios-call" />{{ $t('通话记录') }} </MenuItem>
				<MenuItem  v-if="msgRecordAccess" name="msg-record" :to="{ name: 'msg-record' }"> <Icon type="md-mail" />{{ $t('短信记录') }} </MenuItem>
				<MenuItem name="action-record" :to="{ name: 'action-record' }" v-if="actionRight"> <Icon type="md-build" />{{ $t('操作记录') }} </MenuItem>
				<MenuItem name="commissioner-call-record" :to="{ name: 'commissioner-call-record' }" v-hasPerm="['dial_logs.getDialogList']">
					<Icon type="md-build" />{{ $t('专员通话总览') }}
				</MenuItem>
				<MenuItem name="wacontact-record" :to="{ name: 'wacontact-record' }" v-if="oaContactRight"> <Icon type="md-people" />{{ $t('OA通讯录') }} </MenuItem>
				<!-- <MenuItem name="recharge-record" :to="{name:'recharge-record'}" v-if="rechargeRight">
          <Icon type="logo-yen" />充值记录
        </MenuItem>
        <MenuItem name="transfer-record" :to="{name:'transfer-record'}" v-if="transferRight">
          <Icon type="md-exit" />转账记录
        </MenuItem> -->
			</MenuGroup>
			<MenuGroup :title="$t('团队')" v-if="organizationRight || personRight || socialRight">
				<MenuItem name="organization-architecture" :to="{ name: 'organization-architecture' }" v-if="organizationRight">
					<Icon type="md-git-network" />{{ $t('组织架构') }}
				</MenuItem>
				<MenuItem name="permission-manage" :to="{ name: 'permission-manage' }" v-if="PermissionsRight">
					<Icon type="md-person" />{{ $t('权限管理') }}
				</MenuItem>

				<MenuItem name="person-manage" :to="{ name: 'person-manage' }" v-if="personRight"> <Icon type="md-person" />{{ $t('人员管理') }} </MenuItem>
				<MenuItem name="social-software" :to="{ name: 'social-software' }" v-if="socialRight"> <Icon type="logo-codepen" />{{ $t('社交软件') }} </MenuItem>
				<MenuItem name="sms-channel" :to="{ name: 'sms-channel' }" v-if="SMSChannelRight"> <Icon type="md-chatbubbles" />{{ $t('SMS渠道配置') }} </MenuItem>
				<MenuItem name="sms-template" :to="{ name: 'sms-template' }" v-if="SMSTemplateRight"> <Icon type="md-chatbubbles" />{{ $t('SMS模板配置') }} </MenuItem>
				<MenuItem name="manager-user" :to="{ name: 'manager-user' }" v-if="ManagerUserRight"> <Icon type="md-person" />{{ $t('专员会员关系管理') }} </MenuItem>
			</MenuGroup>
			<MenuGroup :title="$t('报表')" v-if="newRegisterRight || compreRight || dialReportRight || smsReportRight">
				<!-- <MenuItem name="comprehensive-chart" :to="{name:'comprehensive-chart'}" v-if="compreRight">
          <Icon type="ios-stats" />综合报表
        </MenuItem> -->
				<MenuItem name="call-statistics-report" :to="{ name: 'call-statistics-report' }" v-if="dialReportManagerdialRight"> <Icon type="logo-whatsapp" />{{ $t('通话统计看板') }} </MenuItem>
				<MenuItem name="call-report" :to="{ name: 'call-report' }" v-if="dialReportRight"> <Icon type="logo-whatsapp" />{{ $t('通话报表') }} </MenuItem>
				<MenuItem name="sms-report" :to="{ name: 'sms-report' }" v-if="smsReportRight"> <Icon type="ios-text" />{{ $t('短信报表') }} </MenuItem>
				<MenuItem name="new-register" :to="{ name: 'new-register' }" v-if="newRegisterRight"> <Icon type="ios-people" />{{ $t('新注册用户') }} </MenuItem>
			</MenuGroup>
		</Menu>
	</div>
</template>
<script>
import Routers from '@/router/routers'
import { hasOneOf } from '@/libs/utils'
// const menuList = Routers[0].children.slice(1, Routers[0].children.length)
export default {
	data() {
		return {
			theme3: 'light'
			// menuList: menuList
		}
	},
	computed: {
		menuList() {
			let data = Routers[0].children.filter((_) => {
				return _.hideInMenu !== false
			})
			return data
		},
		access() {
			return this.$store.state.user.access
		},
		kanbanAccess() {
			return hasOneOf(['managers.getTenantMangers'], this.access)
		},
		memberListAccess() {
			return hasOneOf(['fs_users.myusers'], this.access)
		},
		agentsListAccess() {
			return hasOneOf(['agent.index'], this.access)
		},
		agentDetailAccess(){
			return hasOneOf(['agentdetail.index'], this.access)
		},
		importMemberAccess() {
			return localStorage.getItem('code') === '00032'
		},
		// 导入权限
		importPhoneAccess() {
			return hasOneOf(['imports.list'], this.access)
		},
		// 更换手机号权限
		reviewListAccess() {
			return hasOneOf(['review.list'], this.access)
		},
		// 群呼任务
		callTaskQueryAccess() {
			return hasOneOf(['okcc.callTask_query'], this.access)
		},
		// 意向会员权限
		intendedMemberAccess() {
			return hasOneOf(['fs_users.search_users'], this.access)
		},
		// 操作台
		membercontrolAccess() {
			return hasOneOf(['membercontrol.searchmembersone'], this.access)
		},
		// 查看工作记录
		viewWorkRecords() {
			return hasOneOf(['work_records.list'], this.access)
		},
		//申诉列表
		memberAppealAccess(){
			return hasOneOf(['activationcode.index'], this.access)
		},
		//激活会员活动码
		memberActiveAccess(){
			return hasOneOf(['activationcode.activeactivationcode'], this.access)
		},
		// 会员资料
		usersMemberAccess() {
			return hasOneOf(['fs_users.search'], this.access)
		},
		// 客服会员资料
		customerUsersMemberAccess() {
			return hasOneOf(['fs_users.searchcustomer'], this.access)
		},
		// 拉回会员查看权限
		pullbackMemberAccess() {
			return hasOneOf(['fs_users_info.search'], this.access)
		},
		// 目标会员
		targetMemberAccess() {
			return hasOneOf(['fs_users.target_members'], this.access)
		},
		// 渠道管理权限
		channelAccess() {
			return hasOneOf(['channel'], this.access)
		},
		// 电销数据
		dxDataAccess() {
			return hasOneOf(['tmk.statistics'], this.access)
		},
		// 会员列表权限
		// 平台码
		// 万博：00004
		// 亚冠：00007
		// 亚投：00008
		// 宏图：00030
		// 新一：00029
		// 亿彩：00021
		// 51：00031
		// drxy：00033
		memberListRight() {
			const code = localStorage.getItem('code')
			const includeCode = ['00001', '00004', '00007', '00008', '00030', '00029', '00021', '00031', '00033', '00015', '00047']
			return includeCode.indexOf(code) > -1
		},
		// 组织架构权限
		memberLevelRight() {
			return hasOneOf(['ranks.index'], this.access)
		},
		// 会员层级
		memberVipLevel() {
			return hasOneOf(['commons.UserLev'], this.access)
		},
		// 组织架构权限
		organizationRight() {
			return hasOneOf(['departments.index'], this.access)
		},
		// 组织架构权限
		PermissionsRight() {
			return hasOneOf(['permission_manage.index'], this.access)
		},
		// 人员管理权限
		personRight() {
			return hasOneOf(['managers.index'], this.access)
		},
		// 社交软件权限
		socialRight() {
			// logs.index
			return hasOneOf(['ims.index'], this.access)
		},
		// 操作记录权限
		actionRight() {
			return hasOneOf(['logs.index'], this.access) //
		},
		// 新注册用户权限
		newRegisterRight() {
			return hasOneOf(['reports.newer'], this.access) // reports.newer
		},
		// 查看代理列表权限
		proxyRight() {
			return hasOneOf(['fs_agents.search'], this.access) //
		},
		// 查看综合图表权限
		compreRight() {
			return hasOneOf(['reports.manager'], this.access) //
		},
		// 查看通话统计看板权限
		dialReportManagerdialRight() {
			return hasOneOf(['reports.managerdial'], this.access) //
		},
		// 查看通话报表权限
		dialReportRight() {
			return hasOneOf(['reports.dial'], this.access) //
		},
		// 查看短信报表权限
		smsReportRight() {
			return hasOneOf(['reports.sms'], this.access) //
		},
		// 充值记录权限
		rechargeRight() {
			return hasOneOf(['pays.pay_log'], this.access) //
		},
		// 转账权限
		transferRight() {
			return hasOneOf(['pays.wallet_log'], this.access) //
		},
		// 登录日志权限
		loginLogRight() {
			return hasOneOf(['fs_login_logs.index'], this.access)
		},
		// 测试拨打权限
		testCallRight() {
			return hasOneOf(['phone_checks.list'], this.access)
		},
		// 名单管理权限
		packageRight() {
			return hasOneOf(['user_packages.index'], this.access)
		},
		// 业绩管理权限
		commissionScheduleRight() {
			return hasOneOf(['commission'], this.access)
		},
		// 专员数据统计权限
		settlementRight() {
			return hasOneOf(['settlement.index'], this.access)
		},
		// SMS渠道配置
		SMSChannelRight() {
			return hasOneOf(['settings.getSMSChannels'], this.access)
		},
		// SMS模版
		SMSTemplateRight() {
			return hasOneOf(['smstemplate.index'], this.access)
		},
		// 专员用户分配管理
		ManagerUserRight() {
			return hasOneOf(['manageruser.index'], this.access)
		},
		// 短信记录
		msgRecordAccess() {
			return hasOneOf(['msgrecord.index'], this.access)
		},
		// 查看OA记录权限
		oaContactRight() {
			return hasOneOf(['wacontact.index'], this.access) //
		},
		// 重复会员查看权限
		duplicateMemberRight() {
			return hasOneOf(['duplicatemember.list'], this.access)
		},
		//会员笔记查看权限
		memberNoteRight() {
			return hasOneOf(['membernote.list'], this.access)
		},
	},
	methods: {}
}
</script>
<style lang="less" scoped>
.ele-menu {
	width: 200px;
	height: calc(~'100% - 2px');
	overflow-x: hidden;
	overflow-y: auto;
	border-right: 1px solid #f8f8f9;
}
.ele-menu::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.ele-menu::-webkit-scrollbar-track {
	background-color: #f8f8f9;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}

.ele-menu::-webkit-scrollbar-thumb {
	background-color: #e8eaec;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}
</style>
